<script setup lang="ts">
import { computed } from 'vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseMenu from '/~/components/base/menu/base-menu.vue'
import { usePointsProgramsModals } from '/~/templates/bill-payments/composables'
import { PointsPartner } from '/~/templates/bill-payments/core/points-partner'

const props = withDefaults(
  defineProps<{
    partner?: PointsPartner
    disabled?: boolean
    arrow?: boolean
    fill?: boolean
    counter?: boolean
    showMenu?: boolean
  }>(),
  {
    partner: () => ({}),
    disabled: false,
    arrow: true,
    fill: true,
    counter: false,
    showMenu: true,
  }
)

const emit = defineEmits<{
  (event: 'click', payload: Event): void
}>()

const { showRemoveAccountModal } = usePointsProgramsModals()

const icon = computed(() => {
  return props.partner.icon
})
const description = computed(() => {
  return props.partner.description
})
const number = computed(() => {
  return props.partner.number
})
const count = computed(() => {
  return props.partner.count
})
const hasCount = computed(() => {
  return count.value > 0
})
const menuItems = computed(() => {
  return [
    {
      content: 'Transfer points',
      click: () => onClick(),
    },
    {
      content: 'Remove',
      click: () => onDelete(),
    },
  ]
})

const partnerName = computed(() => {
  const { firstName, lastName } = props.partner ?? {}
  const result = firstName === lastName ? firstName : `${firstName} ${lastName}`

  return result || 'Select partner'
})

function onClick() {
  if (!props.disabled) {
    emit('click', props.partner)
  }
}
async function onDelete() {
  await showRemoveAccountModal(props.partner)
}
</script>

<template>
  <div
    class="flex min-h-20 shrink-0 rounded-md border py-4 outline outline-2 outline-transparent transition-all duration-1000 sm:rounded-lg"
    :class="{
      'hover:outline-primary': !disabled && arrow,
      'bg-white px-4 sm:px-5': fill,
    }"
    @click="onClick"
  >
    <div
      class="flex w-full items-center"
      :class="{
        'cursor-pointer': !disabled && arrow,
        'opacity-25': disabled,
      }"
    >
      <div class="mr-5 flex w-20 items-center justify-center">
        <base-image class="h-full w-full" :src="icon" :ratio="false" />
      </div>
      <div class="text-sm">
        <div>
          <div class="font-bold">
            {{ partner.name || partnerName || 'Select partner' }}
          </div>
          <div v-if="counter && hasCount" class="text-sm text-neutral-400">
            Accounts added ({{ count }})
          </div>
          <div v-if="description" class="text-sm text-neutral-400">
            {{ description }}
          </div>
        </div>
        <div v-if="number" class="text-sm text-eonx-neutral-600">
          {{ number }}
        </div>
      </div>
      <div class="ml-auto flex h-10 flex-col justify-center">
        <base-icon
          v-if="arrow && !disabled"
          svg="plain/chevron-right.v2"
          size="md"
        />

        <base-menu
          v-else-if="showMenu && !disabled"
          ref="menu"
          :items="menuItems"
          position="right"
          class="mt-2"
        >
          <template #trigger>
            <base-button
              icon="heroicons/outline/ellipsis-vertical"
              alt="close"
              :size="28"
              :padding="2"
            />
          </template>
        </base-menu>
      </div>
    </div>
  </div>
</template>
